import React from 'react';
import {
    Route,
} from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import SignIn from './SignIn';
import Dashboard from './Dashboard';
import AreasTable from './tables/AreasTable';
import CampaignsTable from './tables/CampaignsTable';
import UsersTable from './tables/UsersTable';
import AdsTable from './tables/AdsTable';
import feathersApp from '../services/FeathersService';

class App extends React.Component {
    constructor() {
        super();

        this.state = {
            authenticated: false,
        };

        this.onAuthenticated = this.onAuthenticated.bind(this);
    }

    onAuthenticated(authenticated) {
        this.setState(prevState => ({
            ...prevState,
            authenticated,
        }));
    }

    render() {
        const { authenticated } = this.state;
        const routes = (
            <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <React.Fragment>
                    <Route exact path="/" render={() => <Dashboard authenticated={this.onAuthenticated} feathersApp={feathersApp} />} />
                    <Route path="/ads" render={() => <Dashboard authenticated={this.onAuthenticated} component={<AdsTable feathersApp={feathersApp} />} feathersApp={feathersApp} />} />
                    <Route path="/areas" render={() => <Dashboard authenticated={this.onAuthenticated} component={<AreasTable feathersApp={feathersApp} />} feathersApp={feathersApp} />} />
                    <Route path="/campaigns" render={() => <Dashboard authenticated={this.onAuthenticated} component={<CampaignsTable feathersApp={feathersApp} />} feathersApp={feathersApp} />} />
                    <Route path="/users" render={() => <Dashboard authenticated={this.onAuthenticated} component={<UsersTable feathersApp={feathersApp} />} feathersApp={feathersApp} />} />
                </React.Fragment>
            </SnackbarProvider>
        );

        return (
            authenticated ? (
                routes
            ) : (
                <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                    <SignIn authenticated={this.onAuthenticated} feathersApp={feathersApp} />
                </SnackbarProvider>
            )
        );
    }
}

export default App;
