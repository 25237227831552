import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { withSnackbar } from 'notistack';
import { authenticate, isAuthenticated } from '../services/AuthenticationService';

const styles = theme => ({
    layout: {
        width: 'auto',
        display: 'block', // Fix IE11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
    },
});


class SignIn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            authenticating: false,
        };
        this.onEmailChange = this.onEmailChange.bind(this);
        this.onPasswordChange = this.onPasswordChange.bind(this);
        this.submit = this.submit.bind(this);
    }

    componentWillMount() {
        const { feathersApp, authenticated } = this.props;
        isAuthenticated(feathersApp).then(() => { authenticated(true); });
    }

    onEmailChange(event) {
        this.setState({
            email: event.target.value,
        });
    }

    onPasswordChange(event) {
        this.setState({
            password: event.target.value,
        });
    }

    submit(event) {
        event.preventDefault();
        const { email, password } = this.state;
        const { authenticated, enqueueSnackbar, feathersApp } = this.props;

        authenticate(feathersApp, email, password).then(() => {
            this.setState({ authenticating: false });
            authenticated(true);
        }, (err) => {
            // eslint-disable-next-line no-console
            console.error(err);
            this.setState({ authenticating: false });
            enqueueSnackbar('E-mail or password is invalid', { variant: 'error' });
        });
        this.setState({ authenticating: true });
    }

    render() {
        const { classes } = this.props;
        const { authenticating } = this.state;

        return (
            <div>
                <React.Fragment>
                    <CssBaseline />
                    <main className={classes.layout}>
                        <Paper className={classes.paper}>
                            <Avatar className={classes.avatar}>
                                <LockIcon />
                            </Avatar>
                            <Typography component="h1" variant="headline">Sign in</Typography>
                            <form className={classes.form}>
                                <FormControl margin="normal" required fullWidth>
                                    <InputLabel htmlFor="email">Email Address</InputLabel>
                                    <Input onChange={this.onEmailChange} id="email" name="email" autoComplete="email" autoFocus />
                                </FormControl>
                                <FormControl margin="normal" required fullWidth>
                                    <InputLabel htmlFor="password">Password</InputLabel>
                                    <Input
                                        onChange={this.onPasswordChange}
                                        name="password"
                                        type="password"
                                        id="password"
                                        autoComplete="current-password"
                                    />
                                </FormControl>
                                <FormControlLabel
                                    control={<Checkbox value="remember" color="primary" />}
                                    label="Remember me"
                                />
                                <Button
                                    onClick={this.submit}
                                    type="submit"
                                    disabled={authenticating}
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                >
                                    {authenticating ? 'Signing in...' : 'Sign in'}
                                </Button>
                            </form>
                        </Paper>
                    </main>
                </React.Fragment>
            </div>
        );
    }
}
SignIn.propTypes = {
    authenticated: PropTypes.func.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    feathersApp: PropTypes.object.isRequired,
    classes: PropTypes.shape({
        layout: PropTypes.string,
        paper: PropTypes.string,
        avatar: PropTypes.string,
        form: PropTypes.string,
        submit: PropTypes.string,
    }).isRequired,
};

export default withSnackbar(withStyles(styles)(SignIn));
