import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router';
import createBrowserHistory from 'history/createBrowserHistory';
import App from './components/App';
import '@babel/polyfill';

const history = createBrowserHistory();

const theme = createMuiTheme({
    palette: {
        primary: { main: '#3689b2' },
        secondary: { main: '#0f3649' },
    },
});

const Start = () => (
    <Router history={history}>
        <MuiThemeProvider theme={theme}>
            <App />
        </MuiThemeProvider>
    </Router>
);

ReactDOM.render(<Start />, document.getElementById('root'));
