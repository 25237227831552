import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';

function getModalStyle() {
    const top = 30;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const styles = theme => ({
    paper: {
        position: 'absolute',
        width: theme.spacing.unit * 60,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
    },
    button: {
        margin: theme.spacing.unit,
    },
    buttonSubmit: {
        paddingTop: theme.spacing.unit,
    },
    extendedIcon: {
        marginRight: theme.spacing.unit,
    },
    leftIcon: {
        marginRight: theme.spacing.unit,
    },
    iconSmall: {
        fontSize: 20,
    },
});

class DeleteItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    handleOpen() {
        this.setState({ open: true });
    }

    handleDelete() {
        const {
            remove,
        } = this.props;
        remove();
        this.setState({ open: false });
    }

    handleClose() {
        this.setState({ open: false });
    }

    render() {
        const { classes } = this.props;
        const { open } = this.state;

        return (
            <div>
                <Button onClick={this.handleOpen}>
                    <DeleteIcon className={classes.icon} />
                </Button>
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={open}
                    onClose={this.handleClose}
                >
                    <div style={getModalStyle()} className={classes.paper}>
                        <Typography variant="title" color="inherit" noWrap>Do you really want to delete this item?</Typography>
                        <Button onClick={this.handleDelete} variant="contained" size="small" className={classes.button}>
                            Confirm
                        </Button>
                        <Button onClick={this.handleClose} variant="contained" size="small" className={classes.button}>
                            Cancel
                        </Button>
                    </div>
                </Modal>
            </div>
        );
    }
}

DeleteItem.propTypes = {
    remove: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    classes: PropTypes.object.isRequired,
};

// We need an intermediary variable for handling the recursive nesting.
const DeleteItemModal = withStyles(styles)(DeleteItem);

export default DeleteItemModal;
