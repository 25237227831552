export default async function update(
    feathersApp,
    id,
    name,
    company,
    description,
) {
    const response = feathersApp.service('ads').patch(id, {
        name,
        company,
        description,
    });
    return response;
}
