import io from 'socket.io-client';
import feathers from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import auth from '@feathersjs/authentication-client';

import config from '../config.json';

// Set up socket.io
const socket = io((new URL(config.url)).origin, { path: config.socketPath });

// Set up Feathers client side
const app = feathers();

// Register socket.io
app.configure(socketio(socket, { timeout: 30000 }));
app.configure(auth({ storage: localStorage }));

export default app;
