import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { withSnackbar } from 'notistack';
import { Typography, TableHead } from '@material-ui/core';
import AddEditItemModal from '../modal/AddEditItemModal';
import update from '../../services/AdsService';


const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
    },
    table: {
        minWidth: 1020,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
});

class AdsTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ads: [],
        };
        this.updateTable = this.updateTable.bind(this);
    }

    async componentDidMount() {
        this.loadTableData();
    }

    async loadTableData() {
        const { feathersApp } = this.props;
        // Get the messages service that talks to the server
        const ads = feathersApp.service('ads');
        // Log newly created messages
        this.setState({ ads: (await ads.find()).data });
    }

    updateTable() {
        this.loadTableData();
    }

    update(feathersApp, id, name, company, description) {
        const { enqueueSnackbar } = this.props;

        update(feathersApp, id, name, company, description).then(() => {
            this.updateTable();
        }, (err) => {
            // eslint-disable-next-line no-console
            console.error(err);
            enqueueSnackbar('Error updating item', { variant: 'error' });
        });
    }

    render() {
        const { classes, feathersApp } = this.props;
        const { ads } = this.state;
        return (

            <div>
                <Typography variant="display1" gutterBottom>Ads</Typography>
                <Paper className={classes.root}>
                    <div className={classes.tableWrapper}>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Company</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Edit</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {ads.map(n => (
                                    // eslint-disable-next-line no-underscore-dangle
                                    <TableRow key={n._id}>
                                        <TableCell>{n.name}</TableCell>
                                        <TableCell>{n.company}</TableCell>
                                        <TableCell>{n.description}</TableCell>
                                        <TableCell>
                                            <AddEditItemModal
                                                data={[
                                                    {
                                                        id: 'name',
                                                        label: 'Name',
                                                        value: n.name,
                                                        required: true,
                                                    },
                                                    {
                                                        id: 'company',
                                                        label: 'Company',
                                                        value: n.company,
                                                        required: true,
                                                    },
                                                    {
                                                        id: 'description',
                                                        label: 'Description',
                                                        value: n.description,
                                                        required: true,
                                                    },
                                                ]}
                                                update={dataValues => this.update(
                                                    feathersApp,
                                                    // eslint-disable-next-line no-underscore-dangle
                                                    n._id,
                                                    dataValues.name,
                                                    dataValues.company,
                                                    dataValues.description,
                                                )}
                                                edit
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </Paper>
            </div>
        );
    }
}

AdsTable.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    feathersApp: PropTypes.object.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
    classes: PropTypes.shape({
        root: PropTypes.string,
        table: PropTypes.string,
    }).isRequired,
};

export default withSnackbar(withStyles(styles)(AdsTable));
