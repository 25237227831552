import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import HomeIcon from '@material-ui/icons/Home';
import LayersIcon from '@material-ui/icons/Layers';
import AnnouncementIcon from '@material-ui/icons/Announcement';

const styles = () => ({
    root: {
        display: 'flex',
    },
});

const links = [
    {
        to: '/',
        label: 'Dashboard',
        icon: <DashboardIcon />,
    },
    {
        to: '/ads',
        label: 'Ads',
        icon: <AnnouncementIcon />,
    },
    {
        to: '/areas',
        label: 'Areas',
        icon: <LayersIcon />,
    },
    {
        to: '/campaigns',
        label: 'Campaigns',
        icon: <HomeIcon />,
    },
    {
        to: '/users',
        label: 'Users',
        icon: <PeopleIcon />,
    },
];

const MenuItems = ({ location }) => (
    <React.Fragment>
        {links.map(link => (
            <ListItem
                key={link.to}
                button
                component={Link}
                to={link.to}
                selected={location.pathname === link.to}
            >
                <ListItemIcon>
                    {link.icon}
                </ListItemIcon>
                <ListItemText primary={link.label} />
            </ListItem>
        ))}
    </React.Fragment>
);

MenuItems.propTypes = {
    classes: PropTypes.shape({
        root: PropTypes.string,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }).isRequired,
};

export default withStyles(styles)(withRouter(MenuItems));
