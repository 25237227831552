
export async function add(
    feathersApp, name, ad, company, description, dateFrom, dateTo, areas,
) {
    const response = feathersApp.service('campaigns').create({
        name, ad, company, description, dateFrom, dateTo, areas,
    });
    return response;
}

export async function update(
    feathersApp, id, name, ad, company, description, dateFrom, dateTo, areas,
) {
    const response = feathersApp.service('campaigns').update(id, {
        name, ad, company, description, dateFrom, dateTo, areas,
    });
    return response;
}

export async function remove(feathersApp, id) {
    const response = feathersApp.service('campaigns').remove(id);
    return response;
}
