import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withSnackbar } from 'notistack';
import DeleteItemModal from '../modal/DeleteItemModal';
import AddEditItemModal from '../modal/AddEditItemModal';
import { add, update, remove } from '../../services/CampaignsService';

const styles = {
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
};

class CampaignsTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            campaigns: [],
            areas: [],
            ads: [],
        };
    }

    async componentDidMount() {
        this.loadTableData();
    }

    async loadTableData() {
        const { feathersApp } = this.props;
        // Get the messages service that talks to the server
        const campaigns = feathersApp.service('campaigns');
        const areas = feathersApp.service('areas');
        const ads = feathersApp.service('ads');
        // Log newly created messages
        this.setState({
            campaigns: (await campaigns.find()).data,
            areas: (await areas.find()).data,
            ads: (await ads.find()).data,
        });
    }

    updateTable() {
        this.loadTableData();
    }

    // eslint-disable-next-line class-methods-use-this
    remove(feathersApp, id) {
        const { enqueueSnackbar } = this.props;

        remove(feathersApp, id).then(() => {
            this.updateTable();
        }, (err) => {
            // eslint-disable-next-line no-console
            console.error(err);
            enqueueSnackbar('Error removing item', { variant: 'error' });
        });
    }

    // eslint-disable-next-line class-methods-use-this
    add(feathersApp, name, ad, company, description, dateFrom, dateTo, areas) {
        const { enqueueSnackbar } = this.props;

        add(feathersApp,
            name,
            ad,
            company,
            description,
            dateFrom,
            dateTo,
            areas).then(() => {
            this.updateTable();
        }, (err) => {
            // eslint-disable-next-line no-console
            console.error(err);
            enqueueSnackbar('Error adding item', { variant: 'error' });
        });
    }

    // eslint-disable-next-line class-methods-use-this
    update(feathersApp, id, name, ad, company, description, dateFrom, dateTo, areas) {
        const { enqueueSnackbar } = this.props;

        update(
            feathersApp,
            id,
            name,
            ad,
            company,
            description,
            dateFrom,
            dateTo,
            areas,
        ).then(() => {
            this.updateTable();
        }, (err) => {
            // eslint-disable-next-line no-console
            console.error(err);
            enqueueSnackbar('Error updating item', { variant: 'error' });
        });
    }

    render() {
        const { classes, feathersApp } = this.props;
        const { campaigns, areas, ads } = this.state;
        return (
            <div>
                <Typography variant="display1" gutterBottom>Campaigns</Typography>
                <AddEditItemModal
                    data={[{
                        id: 'name',
                        label: 'Name',
                        value: '',
                        required: true,
                    },
                    {
                        id: 'ad',
                        label: 'Ad Name',
                        value: '',
                        required: true,
                    },
                    {
                        id: 'company',
                        label: 'Company',
                        value: '',
                        required: true,
                    },
                    {
                        id: 'description',
                        label: 'Description',
                        value: '',
                        required: true,
                    },
                    {
                        id: 'dateFrom',
                        label: 'Date From',
                        value: '',
                        required: true,
                    },
                    {
                        id: 'dateTo',
                        label: 'Date To',
                        value: '',
                        required: true,
                    },
                    {
                        id: 'areas',
                        label: 'Areas',
                        value: '',
                        required: true,
                    },
                    ]}
                    add={dataValues => this.add(
                        feathersApp,
                        dataValues.name,
                        dataValues.ad,
                        dataValues.company,
                        dataValues.description,
                        dataValues.dateFrom,
                        dataValues.dateTo,
                        dataValues.areas,
                    )}
                    areas={areas}
                    ads={ads}
                />
                <Paper className={classes.root}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Ad Name</TableCell>
                                <TableCell>Company</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Date From</TableCell>
                                <TableCell>Date To</TableCell>
                                <TableCell>Areas</TableCell>
                                <TableCell>Edit</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {campaigns.map(n => (
                                // eslint-disable-next-line react/no-array-index-key
                                // eslint-disable-next-line no-underscore-dangle
                                <TableRow key={n._id}>
                                    <TableCell>{n.name}</TableCell>
                                    <TableCell>{ads && ads.find(ad => (
                                        // eslint-disable-next-line no-underscore-dangle
                                        ad._id === n.ad
                                    )).name
                                    }
                                    </TableCell>
                                    <TableCell>{n.company}</TableCell>
                                    <TableCell>{n.description}</TableCell>
                                    <TableCell>{n.dateFrom}</TableCell>
                                    <TableCell>{n.dateTo}</TableCell>
                                    <TableCell>
                                        {n.areas && n.areas.split(',').filter(area => !!area).map(areaId => (
                                            // eslint-disable-next-line no-underscore-dangle
                                            areas.find(area => area._id === areaId).name
                                        )).join(', ')}
                                    </TableCell>
                                    <TableCell>
                                        <DeleteItemModal
                                            // eslint-disable-next-line no-underscore-dangle
                                            remove={() => this.remove(feathersApp, n._id)}
                                        />
                                        <AddEditItemModal
                                            data={[{
                                                id: 'name',
                                                label: 'Name',
                                                value: n.name,
                                                required: true,
                                            },
                                            {
                                                id: 'ad',
                                                label: 'Ad Name',
                                                value: n.ad,
                                                required: true,
                                            },
                                            {
                                                id: 'company',
                                                label: 'Company',
                                                value: n.company,
                                                required: true,
                                            },
                                            {
                                                id: 'description',
                                                label: 'Description',
                                                value: n.description,
                                                required: true,
                                            },
                                            {
                                                id: 'dateFrom',
                                                label: 'Date From',
                                                value: n.dateFrom,
                                                required: true,
                                            },
                                            {
                                                id: 'dateTo',
                                                label: 'Date To',
                                                value: n.dateTo,
                                                required: true,
                                            },
                                            {
                                                id: 'areas',
                                                label: 'Areas',
                                                value: n.areas,
                                                required: true,
                                            },
                                            ]}
                                            update={dataValues => this.update(
                                                feathersApp,
                                                // eslint-disable-next-line no-underscore-dangle
                                                n._id,
                                                dataValues.name,
                                                dataValues.ad,
                                                dataValues.company,
                                                dataValues.description,
                                                dataValues.dateFrom,
                                                dataValues.dateTo,
                                                dataValues.areas,
                                            )}
                                            areas={areas}
                                            ads={ads}
                                            edit
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Paper>
            </div>
        );
    }
}

CampaignsTable.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    feathersApp: PropTypes.object.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
    classes: PropTypes.shape({
        root: PropTypes.string,
        table: PropTypes.string,
    }).isRequired,
};

export default withSnackbar(withStyles(styles)(CampaignsTable));
