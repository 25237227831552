/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Checkbox,
    ListItemText,
    Input,
    IconButton,
    Icon,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';

const styles = theme => ({
    paper: {
        position: 'relative',
        width: theme.spacing.unit * 35,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
    },
    dateField: {
        width: 182,
    },
    button: {
        margin: theme.spacing.unit,
    },
    buttonSubmit: {
        marginTop: theme.spacing.unit * 3,
    },
    buttonClose: {
        float: 'right',
        marginTop: 0,
    },
    extendedIcon: {
        marginRight: theme.spacing.unit,
    },
    leftIcon: {
        marginRight: theme.spacing.unit,
    },
    iconSmall: {
        fontSize: 20,
    },
    formControl: {
        width: 182,
    },
    range: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
    },
    selectEmpty: {
        marginTop: theme.spacing.unit * 2,
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing.unit * 6,
        right: theme.spacing.unit * 6,
    },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

class AddEditItem extends React.Component {
    constructor(props) {
        super(props);
        const dataValues = {};
        props.data.forEach((field) => {
            dataValues[field.id] = field.value;
        });
        this.state = {
            open: false,
            dataValues,
            selectedAreas: dataValues.areas ? dataValues.areas.split(',') : [],
        };
        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleChangeAreas = this.handleChangeAreas.bind(this);
    }

    handleOpen() {
        this.setState({ open: true });
    }

    handleAdd(e) {
        e.preventDefault();
        const {
            add,
        } = this.props;
        const { dataValues } = this.state;
        add(dataValues);
        this.setState({ open: false });
    }

    handleUpdate(e) {
        e.preventDefault();
        const {
            update,
        } = this.props;
        const { dataValues } = this.state;
        update(dataValues);
        this.setState({ open: false });
    }

    handleClose() {
        this.setState({ open: false });
    }

    handleChange(id, e) {
        const { dataValues } = this.state;
        this.setState({ dataValues: { ...dataValues, [id]: e.target.value } });
    }

    handleChangeAreas(id, e) {
        e.preventDefault();
        let { selectedAreas } = this.state;
        const { dataValues } = this.state;
        selectedAreas = [...e.target.value];
        dataValues[id] = selectedAreas.join(',');
        this.setState({ selectedAreas: [...e.target.value], dataValues: { ...dataValues, [id]: selectedAreas.join(',') } });
    }

    render() {
        const {
            classes,
            data,
            add,
            edit,
            areas,
            ads,
        } = this.props;

        const {
            open,
            dataValues,
            selectedAreas,
        } = this.state;

        let addButton;

        if (edit) {
            addButton = (
                <Button onClick={this.handleOpen}>
                    <EditIcon />
                </Button>
            );
        } else {
            addButton = (
                <Button
                    onClick={this.handleOpen}
                    variant="fab"
                    color="primary"
                    aria-label="Add"
                    className={classes.fab}
                >
                    <AddIcon />
                </Button>
            );
        }

        const inputs = data.map((n) => {
            switch (n.id) {
            case 'ad':
                return (
                    <FormControl key={n.id} required className={classes.formControl}>
                        <InputLabel htmlFor="ad-required" classes={classes.textField}>Ad</InputLabel>
                        <Select
                            // eslint-disable-next-line no-underscore-dangle
                            onChange={e => this.handleChange(n.id, e)}
                            name="ad"
                            inputProps={{
                                id: 'ad-required',
                            }}
                            className={classes.selectEmpty}
                            value={dataValues.ad}
                        >
                            {ads.map(ad => (
                                <MenuItem
                                    // eslint-disable-next-line no-underscore-dangle
                                    key={ad._id}
                                    // eslint-disable-next-line no-underscore-dangle
                                    value={ad._id}
                                >
                                    {ad.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                );
            case 'areas':
                return (
                    <FormControl key={n.id} className={classes.formControl}>
                        <InputLabel htmlFor="select-multiple-checkbox" classes={classes.textField}>Areas</InputLabel>
                        <Select
                            multiple
                            value={selectedAreas}
                            onChange={e => this.handleChangeAreas(n.id, e)}
                            input={<Input id="select-multiple-checkbox" />}
                            // eslint-disable-next-line no-underscore-dangle
                            renderValue={selected => selected.map(areaId => areas.find(area => areaId === area._id).name).join(', ')}
                            MenuProps={MenuProps}
                        >
                            {areas.map(area => (
                                <MenuItem
                                    // eslint-disable-next-line no-underscore-dangle
                                    key={area._id}
                                    // eslint-disable-next-line no-underscore-dangle
                                    value={area._id}
                                >
                                    <Checkbox
                                        checked={
                                            // eslint-disable-next-line no-underscore-dangle
                                            edit ? dataValues[n.id].indexOf(area._id) > -1
                                            // eslint-disable-next-line no-underscore-dangle
                                                : selectedAreas.indexOf(area._id) > -1
                                        }
                                    />
                                    <ListItemText primary={area.name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                );

            case 'dateFrom':
                return (
                    <div className={classes.range} key={n.id}>
                        <TextField
                            id="date-from"
                            label="From"
                            type="date"
                            value={edit ? dataValues[n.id] : undefined}
                            onChange={e => this.handleChange(n.id, e)}
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>
                );
            case 'dateTo':
                return (
                    <div className={classes.range} key={n.id}>
                        <TextField
                            id="date-to"
                            label="To"
                            type="date"
                            value={edit ? dataValues[n.id] : undefined}
                            onChange={e => this.handleChange(n.id, e)}
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>
                );
            case 'password':
                return (
                    <div className={classes.range} key={n.id}>
                        <TextField
                            id={n.id}
                            label={n.label}
                            className={classes.textField}
                            onChange={e => this.handleChange(n.id, e)}
                            type="password"
                            margin="normal"
                            required={n.required}
                        />
                    </div>
                );
            default:
                return (
                    <TextField
                        key={n.id}
                        id={n.id}
                        label={n.label}
                        value={edit ? dataValues[n.id] : undefined}
                        className={classes.textField}
                        onChange={e => this.handleChange(n.id, e)}
                        margin="normal"
                        required={n.required}
                    />
                );
            }
        });

        return (
            <div>
                {addButton}

                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={open}
                    onClose={this.handleClose}
                >
                    <Grid justify="center" alignItems="center" container spacing={24} style={{ minHeight: '100vh' }}>
                        <div className={classes.paper}>
                            <IconButton color="secondary" className={classes.buttonClose} onClick={this.handleClose}>
                                <Icon>close</Icon>
                            </IconButton>
                            <Typography variant="title" color="inherit">Fill in fields!</Typography>

                            <form onSubmit={add ? e => this.handleAdd(e) : e => this.handleUpdate(e)} className={classes.container} autoComplete="off">
                                {inputs}

                                <Button type="submit" variant="contained" size="small" className={classes.buttonSubmit}>
                                    <SaveIcon
                                        className={classNames(classes.leftIcon, classes.iconSmall)}
                                    />
                                    Save
                                </Button>
                            </form>
                        </div>
                    </Grid>
                </Modal>
            </div>
        );
    }
}

AddEditItem.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    data: PropTypes.array.isRequired,
    areas: PropTypes.arrayOf(PropTypes.object),
    ads: PropTypes.arrayOf(PropTypes.object),
    add: PropTypes.func,
    update: PropTypes.func,
    edit: PropTypes.bool,
    // eslint-disable-next-line react/forbid-prop-types
    classes: PropTypes.object.isRequired,
};

AddEditItem.defaultProps = {
    add: undefined,
    update: undefined,
    areas: [],
    ads: [],
    edit: false,
};

// We need an intermediary variable for handling the recursive nesting.
const AddEditItemModal = withStyles(styles)(AddEditItem);

export default AddEditItemModal;
