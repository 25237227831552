
export async function authenticate(feathersApp, email, password) {
    const response = await feathersApp.authenticate({
        strategy: 'local',
        email,
        password,
    });

    const payload = await feathersApp.passport.verifyJWT(response.accessToken);
    const user = await feathersApp.service('users').get(payload.userId);
    feathersApp.set('user', user);
    localStorage.setItem('user', user.email);

    return user;
}

export function logout(feathersApp) {
    feathersApp.logout();
}

export function isAuthenticated(feathersApp) {
    return feathersApp.authenticate();
}
