import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withSnackbar } from 'notistack';
import DeleteItemModal from '../modal/DeleteItemModal';
import AddEditItemModal from '../modal/AddEditItemModal';
import { add, update, remove } from '../../services/UsersService';

const styles = {
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
};

class UsersTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
        };
    }

    async componentDidMount() {
        this.loadTableData();
    }

    async loadTableData() {
        const { feathersApp } = this.props;
        // Get the messages service that talks to the server
        const users = feathersApp.service('users');
        // Log newly created messages
        this.setState({ users: (await users.find()).data });
    }

    updateTable() {
        this.loadTableData();
    }

    // eslint-disable-next-line class-methods-use-this
    remove(feathersApp, id) {
        const { enqueueSnackbar } = this.props;

        remove(feathersApp, id).then(() => {
            this.updateTable();
        }, (err) => {
            // eslint-disable-next-line no-console
            console.error(err);
            enqueueSnackbar('Error updating item', { variant: 'error' });
        });
    }

    // eslint-disable-next-line class-methods-use-this
    add(feathersApp, name, email, role, password) {
        const { enqueueSnackbar } = this.props;

        add(feathersApp, name, email, role, password).then(() => {
            this.updateTable();
        }, (err) => {
            // eslint-disable-next-line no-console
            console.error(err);
            enqueueSnackbar('Error updating item', { variant: 'error' });
        });
    }

    // eslint-disable-next-line class-methods-use-this
    update(feathersApp, id, name, email, role, password) {
        const { enqueueSnackbar } = this.props;

        update(feathersApp, id, name, email, role, password).then(() => {
            this.updateTable();
        }, (err) => {
            // eslint-disable-next-line no-console
            console.error(err);
            enqueueSnackbar('Error updating item', { variant: 'error' });
        });
    }

    render() {
        const { classes, feathersApp } = this.props;
        const { users } = this.state;

        return (
            <div>
                <Typography variant="display1" gutterBottom>Users</Typography>
                <AddEditItemModal
                    data={[{
                        id: 'name',
                        label: 'Name',
                        value: '',
                        required: true,
                    },
                    {
                        id: 'email',
                        label: 'Email',
                        value: '',
                        required: true,
                    },
                    {
                        id: 'password',
                        label: 'Password',
                        value: '',
                        required: true,
                    },
                    ]}
                    add={dataValues => this.add(
                        feathersApp,
                        dataValues.name,
                        dataValues.email,
                        'Administrator',
                        dataValues.password,
                    )}
                />
                <Paper className={classes.root}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Role</TableCell>
                                <TableCell>Edit</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users.map(n => (
                                // eslint-disable-next-line react/no-array-index-key
                                // eslint-disable-next-line no-underscore-dangle
                                <TableRow key={n._id}>
                                    <TableCell>{n.name}</TableCell>
                                    <TableCell>{n.email}</TableCell>
                                    {/* TODO */}
                                    <TableCell>Administrator</TableCell>
                                    <TableCell>
                                        <DeleteItemModal
                                            // eslint-disable-next-line no-underscore-dangle
                                            remove={() => this.remove(feathersApp, n._id)}
                                        />
                                        <AddEditItemModal
                                            data={[{
                                                id: 'name',
                                                label: 'Name',
                                                value: n.name,
                                                required: true,
                                            },
                                            {
                                                id: 'email',
                                                label: 'Email',
                                                value: n.email,
                                                required: true,
                                            },
                                            {
                                                id: 'password',
                                                label: 'Password',
                                                value: '',
                                                required: true,
                                            },
                                            ]}
                                            update={dataValues => this.update(
                                                feathersApp,
                                                // eslint-disable-next-line no-underscore-dangle
                                                n._id,
                                                dataValues.name,
                                                dataValues.email,
                                                'Administrator',
                                                dataValues.password,
                                            )}
                                            edit
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Paper>
            </div>
        );
    }
}

UsersTable.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    feathersApp: PropTypes.object.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
    classes: PropTypes.shape({
        root: PropTypes.string,
        table: PropTypes.string,
    }).isRequired,
};

export default withSnackbar(withStyles(styles)(UsersTable));
