/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import {
    Redirect,
} from 'react-router-dom';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Menu, MenuItem } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import { Doughnut } from 'react-chartjs';

import MenuItems from './MenuItems';
import { logout } from '../services/AuthenticationService';
import read from '../services/EventsService';

const drawerWidth = 240;

const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 20, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        paddingTop: 35,
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing.unit * 7,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing.unit * 9,
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
        height: '100vh',
        overflow: 'auto',
    },
    chartContainer: {
        marginLeft: -22,
    },
    tableContainer: {
        marginTop: 24,
        height: 320,
    },
    avatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: '#6f848c',
    },
    fadeMenu: {
        backgroundColor: '#8A8787',
    },
    paper: {
        width: '100%',
        marginTop: 30,
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
    control: {
        padding: theme.spacing.unit * 2,
    },
    paperText: {
        textAlign: 'center',
    },
    italicPaperText: {
        textAlign: 'center',
        fontStyle: 'italic',
    },
});

class Dashboard extends React.Component {
    constructor(props) {
        super(props);

        this.shouldBeOpen = () => {
            const width = (window.innerWidth > 0)
                ? window.innerWidth : window.screen.width;

            return width > 860;
        };

        this.state = {
            open: this.shouldBeOpen(),
            anchorEl: null,
            loggedOff: false,
            displayedEvents: [],
            conversionEvents: [],
        };
        this.handleClick = this.handleClickAvatar.bind(this);
        this.handleClose = this.handleCloseAvatar.bind(this);
        this.logout = this.handleLogout.bind(this);
        this.handleResize = this.setOpenFromScreenWidth.bind(this);
    }

    componentDidMount() {
        this.setOpenFromScreenWidth();
        window.addEventListener('resize', this.handleResize);

        this.loadCampaigns();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    setOpenFromScreenWidth() {
        const { open } = this.state;

        if (!this.shouldBeOpen()) {
            if (open !== false) {
                this.setState({ open: false });
            }
        } else if (open !== true) {
            this.setState({ open: true });
        }
    }

    async loadCampaigns() {
        const { feathersApp } = this.props;
        const campaigns = feathersApp.service('campaigns');
        this.loadEvents((await campaigns.find()).data);
    }

    loadEvents(campaigns) {
        const { feathersApp } = this.props;

        campaigns.forEach(async (campaign) => {
            const displayedCount = (await read(feathersApp, 'AD_DISPLAYED', campaign._id)).total;
            const conversionCount = (await read(feathersApp, 'AD_CONVERSION', campaign._id)).total;

            this.setState(prevState => ({
                displayedEvents: [
                    ...prevState.displayedEvents,
                    {
                        campaign,
                        count: displayedCount,
                    },
                ],
                conversionEvents: [
                    ...prevState.conversionEvents,
                    {
                        campaign,
                        count: conversionCount,
                    },
                ],
            }));
        });
    }

    handleClickAvatar(event) {
        this.setState({ anchorEl: event.currentTarget });
    }

    handleCloseAvatar() {
        this.setState({ anchorEl: null });
    }

    handleLogout() {
        const { feathersApp } = this.props;
        logout(feathersApp);
        this.setState({ anchorEl: null, loggedOff: true });
    }

    render() {
        const {
            classes,
            authenticated,
            component,
        } = this.props;
        const {
            open,
            anchorEl,
            loggedOff,
            displayedEvents,
            conversionEvents,
        } = this.state;
        const userName = localStorage.getItem('user');
        const openMenu = Boolean(anchorEl);

        if (loggedOff === true) {
            authenticated(false);
            return <Redirect to="/" />;
        }
        const statisticsEl = (
            <Grid container className={classes.root} spacing={16}>
                <Grid item xs={12}>
                    <Grid container spacing={8}>
                        <Grid item xs={12} lg={4} md={6}>
                            <Paper className={classes.paper} elevation={1}>
                                <Typography variant="title" color="inherit" noWrap className={classes.title}>
                                    Displayed event
                                </Typography>
                                <List>
                                    {displayedEvents.map(events => (
                                        <ListItem key={events.campaign._id}>
                                            <ListItemText
                                                primary={events.campaign.name}
                                            />
                                            <ListItemSecondaryAction>
                                                <Typography variant="headline" color="primary" className={classes.paperText}>
                                                    {events.count}
                                                </Typography>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    ))}
                                </List>

                                <Doughnut
                                    data={displayedEvents.map(events => ({
                                        value: events.count,
                                        label: events.campaign.name,
                                    }))}
                                    options={({ responsive: true })}
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} lg={4} md={6}>
                            <Paper className={classes.paper} elevation={1}>
                                <Typography variant="title" color="inherit" noWrap className={classes.title}>
                                    Conversion event
                                </Typography>
                                <List>
                                    {conversionEvents.map(events => (
                                        <ListItem key={events.campaign._id}>
                                            <ListItemText
                                                primary={events.campaign.name}
                                            />
                                            <ListItemSecondaryAction>
                                                <Typography variant="headline" color="primary" className={classes.paperText}>
                                                    {events.count}
                                                </Typography>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    ))}
                                </List>
                                <Doughnut
                                    data={conversionEvents.map(events => ({
                                        value: events.count,
                                        label: events.campaign.name,
                                    }))}
                                    options={({ responsive: true })}
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );

        return (
            <React.Fragment>
                <CssBaseline />
                <div className={classes.root}>
                    <AppBar
                        position="absolute"
                        className={classNames(classes.appBar)}
                    >
                        <Toolbar disableGutters={!open} className={classes.toolbar}>
                            <Typography variant="title" color="inherit" noWrap className={classes.title}>Dashboard</Typography>
                            <IconButton color="inherit">
                                <Avatar
                                    aria-owns={open ? 'fade-menu' : null}
                                    aria-haspopup="true"
                                    onClick={this.handleClick}
                                    className={classes.avatar}
                                    title="Profile"
                                >
                                    {userName.substring(0, 1).toUpperCase()}
                                </Avatar>
                                <Menu
                                    id="fadeMenu"
                                    anchorEl={anchorEl}
                                    open={openMenu}
                                    onClose={this.handleClose}
                                    TransitionComponent={Fade}
                                >
                                    <MenuItem onClick={this.logout}>Logout</MenuItem>
                                </Menu>
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <Drawer
                        variant="permanent"
                        classes={{
                            paper: classNames(
                                classes.drawerPaper, !open && classes.drawerPaperClose,
                            ),
                        }}
                        open={open}
                    >
                        <div className={classes.toolbarIcon}>
                            <IconButton onClick={this.handleDrawerClose}>
                                <ChevronLeftIcon />
                            </IconButton>
                        </div>
                        <Divider />
                        <List><MenuItems /></List>
                        <Divider />
                    </Drawer>
                    <main className={classes.content}>
                        <div className={classes.appBarSpacer} />
                        <div className={classes.tableContainer}>
                            <div>
                                {component || statisticsEl}
                            </div>
                        </div>
                    </main>
                </div>
            </React.Fragment>
        );
    }
}


Dashboard.propTypes = {
    authenticated: PropTypes.func.isRequired,
    // eslint-disable-next-line react/require-default-props
    component: PropTypes.element,
    // eslint-disable-next-line react/forbid-prop-types
    feathersApp: PropTypes.object.isRequired,
    classes: PropTypes.shape({
        root: PropTypes.string,
        toolbar: PropTypes.string,
        toolbarIcon: PropTypes.string,
        appBar: PropTypes.string,
        appBarShift: PropTypes.string,
        menuButton: PropTypes.string,
        menuButtonHidden: PropTypes.string,
        title: PropTypes.string,
        drawerPaper: PropTypes.string,
        drawerPaperClose: PropTypes.string,
        appBarSpacer: PropTypes.string,
        content: PropTypes.string,
        chartContainer: PropTypes.string,
        tableContainer: PropTypes.string,
    }).isRequired,
};

export default withStyles(styles)(Dashboard);
