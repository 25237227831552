import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withSnackbar } from 'notistack';
import DeleteItemModal from '../modal/DeleteItemModal';
import AddEditItemModal from '../modal/AddEditItemModal';
import { add, update, remove } from '../../services/AreasService';

const styles = theme => ({
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
    icon: {
        margin: theme.spacing.unit / 5,
        fontSize: 20,
    },
});

class AreasTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            areas: [],
        };
        this.updateTable = this.updateTable.bind(this);
    }

    async componentDidMount() {
        this.loadTableData();
    }

    async loadTableData() {
        const { feathersApp } = this.props;
        // Get the messages service that talks to the server
        const areas = feathersApp.service('areas');
        // Log newly created messages
        this.setState({ areas: (await areas.find()).data });
    }

    updateTable() {
        this.loadTableData();
    }

    // eslint-disable-next-line class-methods-use-this
    remove(feathersApp, id) {
        const { enqueueSnackbar } = this.props;

        remove(feathersApp, id).then(() => {
            this.updateTable();
        }, (err) => {
            // eslint-disable-next-line no-console
            console.error(err);
            enqueueSnackbar('Error removing item', { variant: 'error' });
        });
    }

    // eslint-disable-next-line class-methods-use-this
    add(feathersApp, name, coordinates, pointsOfInterest, placement, notes) {
        const { enqueueSnackbar } = this.props;

        add(feathersApp, name, coordinates, pointsOfInterest, placement, notes).then(() => {
            this.updateTable();
        }, (err) => {
            // eslint-disable-next-line no-console
            console.error(err);
            enqueueSnackbar('Error adding item', { variant: 'error' });
        });
    }

    // eslint-disable-next-line class-methods-use-this
    update(feathersApp, name, id, coordinates, pointsOfInterest, placement, notes) {
        const { enqueueSnackbar } = this.props;

        update(feathersApp, id, name, coordinates, pointsOfInterest, placement, notes).then(() => {
            this.updateTable();
        }, (err) => {
            // eslint-disable-next-line no-console
            console.error(err);
            enqueueSnackbar('Error updating item', { variant: 'error' });
        });
    }

    render() {
        const { classes, feathersApp } = this.props;
        const { areas } = this.state;

        const areaUrl = slug => (
            <a href={`https://aread.sk/${slug}/`} target="_blank" rel="noopener noreferrer">aread.sk/{slug}</a>
        );

        return (
            <div>
                <Typography variant="display1" gutterBottom>Areas</Typography>
                <AddEditItemModal
                    data={[
                        {
                            id: 'name',
                            label: 'Name',
                            value: '',
                            required: true,
                        },
                        {
                            id: 'coordinates',
                            label: 'Coordinates',
                            value: '',
                            required: true,
                        },
                        {
                            id: 'pointsOfInterest',
                            label: 'Points of interest',
                            value: '',
                            required: true,
                        },
                        {
                            id: 'placement',
                            label: 'Placement',
                            value: '',
                            required: true,
                        },
                        {
                            id: 'notes',
                            label: 'Notes',
                            value: '',
                            required: true,
                        },
                    ]}
                    add={dataValues => this.add(
                        feathersApp,
                        dataValues.name,
                        dataValues.coordinates,
                        dataValues.pointsOfInterest,
                        dataValues.placement,
                        dataValues.notes,
                    )}
                />
                <Paper className={classes.root}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>URL</TableCell>
                                <TableCell>Coordinates</TableCell>
                                <TableCell>Points of interest</TableCell>
                                <TableCell>Placement</TableCell>
                                <TableCell>Notes</TableCell>
                                <TableCell>Edit</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {areas.map(n => (
                                // eslint-disable-next-line no-underscore-dangle
                                <TableRow key={n._id}>
                                    <TableCell>{n.name}</TableCell>
                                    <TableCell>{areaUrl(n.slug)}</TableCell>
                                    <TableCell>{n.coordinates}</TableCell>
                                    <TableCell>{n.pointsOfInterest}</TableCell>
                                    <TableCell>{n.placement}</TableCell>
                                    <TableCell>{n.notes}</TableCell>
                                    <TableCell>
                                        <DeleteItemModal
                                            // eslint-disable-next-line no-underscore-dangle
                                            remove={() => this.remove(feathersApp, n._id)}
                                        />
                                        <AddEditItemModal
                                            data={[
                                                {
                                                    id: 'name',
                                                    label: 'Name',
                                                    value: n.name,
                                                    required: true,
                                                },
                                                {
                                                    id: 'coordinates',
                                                    label: 'Coordinates',
                                                    value: n.coordinates,
                                                    required: true,
                                                },
                                                {
                                                    id: 'pointsOfInterest',
                                                    label: 'Points of interest',
                                                    value: n.pointsOfInterest,
                                                    required: true,
                                                },
                                                {
                                                    id: 'placement',
                                                    label: 'Placement',
                                                    value: n.placement,
                                                    required: true,
                                                },
                                                {
                                                    id: 'notes',
                                                    label: 'Notes',
                                                    value: n.notes,
                                                    required: true,
                                                },
                                            ]}
                                            update={dataValues => this.update(
                                                feathersApp,
                                                // eslint-disable-next-line no-underscore-dangle
                                                n._id,
                                                dataValues.name,
                                                dataValues.coordinates,
                                                dataValues.pointsOfInterest,
                                                dataValues.placement,
                                                dataValues.notes,
                                            )}
                                            edit
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Paper>
            </div>
        );
    }
}

AreasTable.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    feathersApp: PropTypes.object.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
    classes: PropTypes.shape({
        root: PropTypes.string,
        table: PropTypes.string,
    }).isRequired,
};

export default withSnackbar(withStyles(styles)(AreasTable));
