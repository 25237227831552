
export async function add(feathersApp, name, email, role, password) {
    const response = feathersApp.service('users').create({
        name, email, role, password,
    });
    return response;
}

export async function update(feathersApp, id, name, email, role, password) {
    const response = feathersApp.service('users').update(id, {
        name, email, role, password,
    });
    return response;
}

export async function remove(feathersApp, id) {
    const response = feathersApp.service('users').remove(id);
    return response;
}
