
export async function add(feathersApp, name, coordinates, pointsOfInterest, placement, notes) {
    const response = feathersApp.service('areas').create({
        name, coordinates, pointsOfInterest, placement, notes,
    });
    return response;
}

export async function update(feathersApp,
    id,
    name,
    coordinates,
    pointsOfInterest,
    placement,
    notes) {
    const response = feathersApp.service('areas').update(id, {
        name,
        coordinates,
        pointsOfInterest,
        placement,
        notes,
    });
    return response;
}

export async function remove(feathersApp, id) {
    const response = feathersApp.service('areas').remove(id);
    return response;
}
