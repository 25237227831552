
export default function read(
    feathersApp, type, campaignId,
) {
    const response = feathersApp.service('events').find({
        query: {
            type,
            campaign: campaignId,
        },
    });
    return response;
}
